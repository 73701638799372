export const TooltipsDocs = {
    text_card_home: 'Esto es una card del home, aqui va todo el texto informativo sobre la cards del home.',
    text_rues: {
        cod_postal: 'Consulte su Código Postal ingresando a www.codigopostal.gov.co ',
        num_empleados: 'Incluye empleados a término fijo, a término indefinido y temporales',
        num_empl_establecimiento: 'Incluye empleados a término fijo, a término indefinido y temporales',
        proceso_inova: 'Proceso de Innovación',
        protecion_social: 'Persona Natural o Jurídica obligada a realizar aportes o contribuciones a favor propio o de terceros al sistema de seguridad social integral',
        act_economica: 'Recuerde que las clasificaciones informadas deben coincidir con las reportadas en el Registro Único Tributario y si se trata de un proponente persona natural o jurídica inscrita en el Registro Mercantil o de Entidades sin Ánimo de Lucro también deberá coincidir con la información registrada en estos.'
    },
    establecimiento: {
        act_econ: 'Indique una actividad principal y máximo tres actividades secundarias, de acuerdo con el Sistema de Clasificación Industrial Internacional Uniforme (CIIU). '
    }
};
