import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpProxy } from 'src/app/shared/services/http.proxy.class';
import { environment, getCuf, firma, pago } from 'src/environments/environment';
import { retry } from 'rxjs/operators';

@Injectable()
export class PagoService {
    private proxy: HttpProxy;
    constructor(private http: HttpClient) {
        const serviceUri = environment.host;
        const serviceUri2 = environment.host2;
        this.proxy = new HttpProxy(http, serviceUri, serviceUri2);
        // this.proxy.inactiveUse(10);
    }
    // Obtener el listado de cuf dependiendo del idformulario trae firma, pago y descargue
    public getCamarasFirma<T>(cuf, params): Promise<T> {
        return this.proxy.get<T>(getCuf + '/' + cuf + '/camaras').pipe(retry(1)).toPromise();
    }


    public putFirmar<T>(idCuf: string, data: any): Promise<T> {
        const service = `${firma}/generaFirmaRUES/${idCuf}`;
        return this.proxy.put2<T>(service, data).pipe(retry(1)).toPromise();
    }

    public postDowloadZip<T>(idCuf: string, idCamara: string) {
        const service = `${firma}descargaformularios/zip/${idCuf}/${idCamara}/0/1`;
        return this.proxy.binaryDataZip(service);
    }

    public getLiquidar<T>(cuf, params): Promise<T> {
        return this.proxy.get<T>(getCuf + '/' + cuf + '/camaras').pipe(retry(1)).toPromise();
    }

    public postCotizar<T>(idCuf, data: any): Promise<T> {
        const service = `${pago}/cotizacion/${idCuf}`;
        return this.proxy.post2<T>(service, data).pipe(retry(1)).toPromise();
    }

    public cotizar<T>(idCuf) {
        const service = `${pago}/cotizacion/${idCuf}/validar`;
        return this.proxy.axios('get', service)
    }

    public postLiquidar<T>(idCuf, data: any): Promise<T>{
        const service = `${pago}/liquidacion/${idCuf}`;
        return this.proxy.post2<T>(service, data).pipe(retry(2)).toPromise();

    }

}
