import { ViewChild } from '@angular/core';
import { NgForm, ValidationErrors, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/shared/components/messages/message.service';

export abstract class BaseFormulario {

    @ViewChild('customForm', { static: false })
    protected customForm: NgForm;
    protected constructor
        (
            protected messageService: MessageService,
            protected router: Router,
    ) { }


    public validateForm(controls) {
        let errors: any[] = [];
        Object.keys(controls).forEach(key => {
            const control = controls[key];
            if (control instanceof FormGroup) {
                errors = errors.concat(this.validateForm(control.controls));
            }
            const controlErrors: ValidationErrors = controls[key].errors;
            if (controlErrors !== null) {
                Object.keys(controlErrors).forEach(keyError => {
                    let title = `* ${key}`;

                    if (keyError === 'required') {
                        errors.push(title + ' es requerido.');
                    }
                    if (keyError === 'email') {
                        errors.push(title + ' tiene un formato invalido.');
                    }
                    if (keyError === 'min') {
                        errors.push(title + ' presenta un valor menor al permitido.');
                    }
                    if (keyError === 'max') {
                        errors.push(title + ' presenta un valor mayor al permitido.');
                    }
                    if (keyError === 'minlength') {
                        errors.push(title + ' no cumple la longitud mínima requerida.');
                    }
                    if (keyError === 'maxlength') {
                        errors.push(title + ' excede la longitud requerida.');
                    }
                    if (keyError === 'pattern') {
                        errors.push(title + ' no cumple con el formato requerido.');
                    }
                    if (errors.length > 0) {
                        this.messageService.manageFormError(errors);
                        return;
                    }
                });
            }
        });
    }

    protected validate() {
        if (this.customForm.invalid) {
            this.validateForm(this.customForm.controls);
            return false;
        }
        return true;
    }

    public doNavigate(step) {

        const dashboard = '/formulario/dashboard';
        const rues = `${dashboard}/rues`;

        switch (step) {
            case 0:
                this.router.navigate([dashboard]);
                break;
            case 1:
                this.router.navigate([`${rues}/datos-generales`]);

                break;
            case 2:
                this.router.navigate([`${rues}/informacion-financiera`]);

                break;
            case 3:
                this.router.navigate([`${rues}/datos-organizacion`]);
                break;
            case 4:
                this.router.navigate([`${rues}/actividad-economica`]);
                break;
            // case 5:
            //     this.router.navigate([`${rues}/esal`]);
            //     break;
            case 5:
                this.router.navigate([`${rues}/informacion-comercial`]);
                break;
            case 6:
                this.router.navigate([`${dashboard}/establecimientos`]);
                break;
            case 7:
                this.router.navigate([`${dashboard}/establecimientos/8`]);
                break;
            default:
                this.router.navigate(['/formulario/dashboard']);
                break;
        }
    }


    tokenExpirado() {
        localStorage.clear();
        this.router.navigate(['/login']);
        Swal.close();
        this.messageService.expiredToken();
    }

}
